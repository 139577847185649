<template>
  <div>
    <van-cell-group>
      <van-cell title="当前余额">
        <span class="text-base font-bold text-black">{{ balance }}</span>
      </van-cell>
    </van-cell-group>
    <van-cell-group class="my-4">
      <van-cell title="请选择充值金额："> </van-cell>
      <van-grid :column-num="3" square>
        <van-grid-item
          v-for="pack in packs"
          :key="pack.amount"
          @click="() => choose(pack)"
          :class="[
            'text-sm font-medium w-full h-full border-2',
            currentPack &&
              currentPack.amount === pack.amount &&
              'border-red-500 text-red-500 font-bold'
          ]"
        >
          <div>{{ pack.amount }}元</div>
        </van-grid-item>
      </van-grid>
    </van-cell-group>
    <van-submit-bar
      :price="currentPack && currentPack.amount * 100"
      button-text="立即充值"
      :disabled="!currentPack"
      @submit="submit"
    />
  </div>
</template>

<script>
import { Dialog } from 'vant'
export default {
  data() {
    return {
      balance: 0,
      packs: [
        {
          amount: 50
        },
        {
          amount: 100
        },
        {
          amount: 200
        },
        {
          amount: 500
        },
        {
          amount: 1000
        },
        {
          amount: 3000
        }
      ],
      currentPack: null
    }
  },

  methods: {
    choose(pack) {
      this.currentPack = pack
    },

    async submit() {
      this.balance += this.currentPack.amount
      await Dialog({
        message: '充值成功！'
      })
      this.$router.go(-1)
    }
  }
}
</script>

<style>
</style>